import { createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2fe5acc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "costing-parameter-details",
  ref: "materialParametersWrapperRef"
}
const _hoisted_2 = {
  key: 0,
  class: "costing-parameters-table--page-counters",
  id: "costing-parameters-pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], { loading: $setup.initLoading }, null, 8, ["loading"]),
    _createVNode($setup["CostingParametersOptions"]),
    _createElementVNode("div", {
      class: "costing-parameters-table-wrapper",
      ref: "tableRef",
      style: _normalizeStyle({minHeight: `${$setup.maxHeight}px`})
    }, [
      (!$setup.initLoading)
        ? (_openBlock(), _createBlock($setup["NDataTable"], {
            key: 0,
            class: _normalizeClass(["material costing-parameters-table single-header-table", {'disable-scroll': $setup.isEditModeCell}]),
            columns: $setup.columns,
            data: $setup.data,
            "single-line": false,
            "max-height": $setup.maxHeight - $setup.tableHeaderHeight,
            style: _normalizeStyle({height: `${$setup.maxHeight}px`})
          }, null, 8, ["columns", "data", "max-height", "style", "class"]))
        : _createCommentVNode("", true)
    ], 4),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", { class: "costing-parameters-table--counter" }, _toDisplayString($setup.paginationCurrentItemsMessage), 513), [
            [_vShow, !$setup.initLoading]
          ]),
          _withDirectives(_createVNode($setup["NPagination"], {
            class: "costing-parameters-table--pagination",
            page: $setup.currentPage,
            "onUpdate:page": [
              _cache[0] || (_cache[0] = ($event: any) => (($setup.currentPage) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => ($setup.onPaginationUpdated()))
            ],
            "page-count": $setup.totalPages,
            "page-slot": 6
          }, null, 8, ["page", "page-count"]), [
            [_vShow, $setup.totalPages > 1]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}